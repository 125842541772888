import * as Sentry from '@sentry/react';

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    environment: process.env.DEPLOY_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 0.1
  });
}
